<template>
	<div class="wrap">
		<el-page-header @back="$router.go(-1)" content="商户设置" class="margin-bottom margin-top-xs"></el-page-header>
		<div class="form_box">
			<el-form ref="form" :model="form" label-width="140px">
				<el-form-item label="商户名称" prop="merchant_name">
					{{form.merchant_name}}
				</el-form-item>
				<el-form-item label="商户负责人" prop="username" :rules="rules.required">
					<el-input v-model="form.username" placeholder="请输入商户名"></el-input>
				</el-form-item>
				<el-form-item label="手机号" prop="user_phone" :rules="rules.required">
					<el-input v-model="form.user_phone" placeholder="请输入商户手机号"></el-input>
				</el-form-item>
				<el-form-item label="门店须知" prop="notice" :rules="rules.required">
					<el-input v-model="form.notice" placeholder="请输入门店须知" maxlength="100" type="textarea"
						show-word-limit></el-input>
				</el-form-item>
				<el-form-item label="门店公告" prop="announcement">
					<el-input v-model="form.announcement" placeholder="请输入门店须知" maxlength="100" type="textarea"
						show-word-limit></el-input>
				</el-form-item>
				<el-form-item label="每周开店日期" prop="open_start_day" :rules="rules.required">
					<!--					<el-input  v-model="form.open_start_day" placeholder="请输入每周开始开店日期"></el-input>-->
					<el-select v-model="form.open_start_day" placeholder="请输入每周开始开店日期">
						<el-option v-for="(item,index) in openStartDay" :label="item" :value="item" :key="index">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="每周关店店日期" prop="open_end_day" :rules="rules.required">
					<!--					<el-input  v-model="form.open_end_day" placeholder="请输入每周关店店日期"></el-input>-->
					<el-select v-model="form.open_end_day" placeholder="请输入每周开始开店日期">
						<el-option v-for="(item,index) in openStartDay" :label="item" :value="item" :key="index">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="每天开店时间" prop="open_start_hour" :rules="rules.required">
					<!--					<el-input  v-model="form.open_start_hour" placeholder="请输入每天开店时间"></el-input>-->
					<el-select v-model="form.open_start_hour" placeholder="请输入每周开始开店日期">
						<el-option v-for="(item,index) in openStartHour" :label="item" :value="item" :key="index">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="每天关店时间" prop="open_end_hour" :rules="rules.required">
					<!--					<el-input  v-model="form.open_end_hour" placeholder="请输入每天关店时间"></el-input>-->
					<el-select v-model="form.open_end_hour" placeholder="请输入每周开始开店日期">
						<el-option v-for="(item,index) in openStartHour" :label="item" :value="item" :key="index">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="地区" prop="area" :rules="rules.required">
					<el-cascader v-model="form.area" :options="areaOptions" @change="areaChange" style="width: 100%">
					</el-cascader>
				</el-form-item>
				<el-form-item label="详细地址" prop="address" :rules="rules.required">
					<el-input v-model="form.address" placeholder="请输入详细地址" @input="areaChange"></el-input>
				</el-form-item>
				<el-form-item label="经度" prop="longitude" :rules="rules.required">
					<el-input v-model="form.longitude" placeholder="请输入店铺经度"></el-input>
					<span class="text-sm text-grey">点击地图上的位置，自动识别</span>
				</el-form-item>
				<el-form-item label="纬度" prop="latitude" :rules="rules.required">
					<el-input v-model="form.latitude" placeholder="请输入店铺纬度"></el-input>
					<span class="text-sm text-grey">点击地图上的位置，自动识别</span>
				</el-form-item>
				<template>
					<el-form-item label="选择经纬度">
						<div id="container"></div>
					</el-form-item>
				</template>
				<el-form-item label="营业执照">
					<el-image :src="item" v-for="(item,index) in form.yyzz" :key="index" class="yyzz"></el-image>
				</el-form-item>
				<el-form-item label="门店展示">
					<el-upload :action="action" :on-success="handleSuccess" list-type="picture-card"
						:on-preview="handlePictureCardPreview" :on-remove="handleRemove" :file-list="ryzz">
						<i class="el-icon-plus"></i>
					</el-upload>
					<el-dialog :visible.sync="dialogVisible" :append-to-body="true">
						<img width="100%" :src="dialogImageUrl" alt="">
					</el-dialog>
					<div class="tips text-grey text-sm">建议尺寸：750px * 150px，该项图片将展示到MOMEN小程序“服务”页面</div>
				</el-form-item>
				<el-form-item label="营业状态">
					<el-switch v-model="form.is_open" :active-text="form.is_open?'营业中':'未营业'" :active-value="1"
						:inactive-value="0"></el-switch>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="onSubmit">保存</el-button>
					<el-button @click="$router.go(-1)">取消</el-button>
				</el-form-item>
			</el-form>
		</div>
	</div>
</template>


<script>
	// 引入公用表单验证
	import rules from "../../common/common.rules";
	import area from "../../common/area";
	// import TMap from "TMap";
	import xzqh from "../../common/common.xzqh.js";
	import axios from "axios"
	var TMap = window.TMap;
	export default {
		data() {
			return {
				action: axios.defaults.baseURL + this.$api.common.UPLOAD_URL,
				map: undefined, // 地图控件
				checkList: [],
				rules,
				area: area.arrAll,
				roleOptions: [],
				showPasswordInput: true,
				cityArr: [],
				districtArr: [],
				grade: [], //店铺等级
				dialogVisible: false,
				dialogImageUrl: '',
				openStartDay: ['周一', '周二', '周三', '周四', '周五', '周六', '周日'], //每周营业
				openStartHour: ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15',
					'16',
					'17', '18', '19', '20', '21', '22', '23', '24'
				], //每天开始时长
				ryzz: [],
				form: {
					area: [], // 地区
					merchant_name: "", //商户名
					username: "", //用户名
					user_phone: "", //用户电话
					province: "", //省
					city: "", //市
					country: "", //区
					address: "", //详细地址
					longitude: "", //经度
					latitude: "", //维度
					open_start_hour: "", //开始营业  小时
					open_end_hour: "", //结束营业  小时
					open_start_day: "", //每周开始营业
					open_end_day: "", //每周结束营业
					notice: "", //门店须知
					yyzz: [], //营业执照
					ryzz: [], // 门店展示
					is_open: 1, //是否营业
					join_level_id: '' //加盟店铺等级ID
				}
			};
		},
		computed: {
			address() {
				return this.form.province + this.form.city + this.form.country + this.form.address
			},
			areaOptions() {
				return xzqh.addrData2()
			}
		},
		mounted() {
			this.$api.store.getMerchantInfo().then(res => {
				this.form = JSON.parse(JSON.stringify(res.data));
				// 初始化地图
				this.initMap()
				// 初始化省市区
				this.form.area = [res.data.province, res.data.city, res.data.country]

				this.ryzz = this.form.ryzz ? this.form.ryzz.map(item => {
					return {
						url: item
					}
				}) : [];
				console.log(this.form)
			});
		},
		methods: {
			areaChange(value) {
				console.log(value)
				this.form.province = value[0];
				this.form.city = value[1];
				this.form.country = value[2];
				this.changeMapPos();
			},
			changeMapPos() {
				console.log(this.address)
				this.$api.store.geocoder({
					address: this.address,
					key: this.$api.common.TMAP_KEY
				}).then(res => {
					console.log(res.data.result.location)
					this.map.setCenter(new TMap.LatLng(res.data.result.location.lat, res.data.result.location
					.lng));
				})
			},
			initMap() {
				let longitude = this.form.longitude || 34.265809;
				let latitude = this.form.latitude || 117.207778;
				var center = new window.TMap.LatLng(latitude, longitude); //设置中心点坐标
				//初始化地图
				this.map = new window.TMap.Map("container", {
					center: center
				});
				var markerLayer = new TMap.MultiMarker({
					id: 'marker-layer',
					map: this.map,
					geometries: [{
						"position": new TMap.LatLng(latitude, longitude), //点标记坐标位置
					}]
				});

				this.map.on("click", evt => {
					markerLayer.setGeometries([])
					markerLayer.add({
						position: evt.latLng
					});
					var lat = evt.latLng.getLat().toFixed(6);
					var lng = evt.latLng.getLng().toFixed(6);
					this.form.longitude = lng
					this.form.latitude = lat
				})

				console.log(this.form.longitude)
			},
			onSubmit() {
				console.log(this.form)
				this.$refs.form.validate(valid => {
					if (valid) {
						const loading = this.$loading();
						this.$api.store.saveMerchantInfo(this.form).then(res => {
								loading.close();
								console.log(res)
								if (res.code == 200) {
									// 保存成功
									this.$message.success(`保存成功`);
									// this.$router.back();
								} else {
									this.$message.error(res.msg);
								}
							})
							.catch(err => {
								loading.close();
								this.$alert(err);
							});
					} else {
						this.$message.warning(`请检查输入错误`);
						return false;
					}
				});
			},
			//上传成功
			handleSuccess(res) {
				this.form.ryzz.push(res.data)
			},
			handleRemove(file, fileList) {
				this.form.ryzz = []
				fileList.forEach(res => {
					if (res.response && res.response.data) {
						this.form.ryzz.push(res.response.data)
					} else {
						this.form.ryzz.push(res.url)
					}
				})
			},
			handlePictureCardPreview(file) {
				console.log(file)
				this.dialogImageUrl = file.url;
				this.dialogVisible = true;
				this.loading = true;
			}
		}

	};
</script>
<style scoped>
	.form_box {
		max-width: 600px;
		padding-top: 30px;
	}

	#container {
		width: 500px;
		height: 500px;
	}

	#info {
		position: absolute;
		left: 20px;
		top: 20px;
		font-size: 14px;
		background: #FFF;
		width: 270px;
		padding: 10px;
		border-radius: 3px;
	}

	.yyzz {
		width: 200px;
		height: 200px;
	}
</style>
